import { Outlet } from 'react-router-dom';
import Menu from './components/Menu/Menu';

import './style.css';
import './utility.css';


function Home() {
  return (
    <div className="App">
      <Menu />
      <Outlet />
    </div>
  );
}

export default Home;
