import React from 'react';
import { Link } from "react-router-dom";

import './style.css';
import '../../utility.css';

import autori from '../../JSON/autori.json';

import Ricerca from '../Ricerca/Ricerca';
import Header from '../Header/Header';

import {urlify} from '../../utilities.js';



class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.handleOpenClick  = this.handleOpenClick.bind(this);
        this.handleCloseClick = this.handleCloseClick.bind(this);
        this.state = {menuAperto: false};
    }

    handleOpenClick(){
        this.setState({menuAperto: true});
    }

    handleCloseClick(){
        this.setState({menuAperto: false});
    }

    render(){
        const menuAperto = this.state.menuAperto;
        let bottone; 

        if (menuAperto) {
            bottone = ( <CloseMenuBtn onClick={this.handleCloseClick}/> );
            document.body.classList.add('hiddenScroll');
        } else {
            bottone = ( <OpenMenuBtn onClick={this.handleOpenClick}/> );
            document.body.classList.remove('hiddenScroll');
        }

        return (
          <>
            <div className="Navbar">
                <div className="Container flex_row flex__spaced">
                    <Header />
                    <Ricerca />
                    {bottone}
                </div>
            </div>
            <div className={`Menu-Wrapper ${menuAperto ? 'Menu__aperto' : 'Menu__chiuso' }`}>
                <div className="Menu Container">
                <div className="Menu-Autori Menu__blocco">
                    <h4>Autori </h4>
                    {
                        autori.nomi.map((nome,i) => {
                            let url = urlify(nome);
                            return (
                                <Link key={i} className="autore" onClick={this.handleCloseClick} to={`collezione/${url}`}>{nome}</Link>
                            )
    
                        })
                    }
                    <Link className="autore" onClick={this.handleCloseClick} to={`collezione`}>Tutta la Collezione</Link>
                </div>
                <div className="Menu-Contatti Menu__blocco">
                    <div className="Menu-Contatti__blocco">
                        <h4>Orari di apertura:</h4>
                        <p><strong>Solo su appuntamento:</strong><br/>Lun-Ven: 9.00 - 12.00</p>
                        <p><small>
                            Il giovedì è prevista la presenza della Bibliotecaria 
                            per assistenza nelle ricerche e 
                            nella riproduzione del materiale moderno.
                        </small></p>
                    </div>
                    <div className="Menu-Contatti__blocco">
                        <h4>Consultazione in sede:</h4>
                        <p>Richiedi un appuntamento per consultare i testi presso la nostra sede.</p>
                        <p>Scrivici una mail a <a href="mailto:consultazioni@bibliocrf.it" rel="noopener noreferrer">consultazioni@bibliocrf.it</a></p>
                    </div>
                    <div className="Menu-Contatti__blocco">
                        <h4>Biblioteca Digitale:</h4>
                        <p className="bigText">
                            Raccolta di libri rari e antichi, restaurati e scansionati per essere consultati liberamente. 
                            Clicca su un libro per leggerlo o per avere maggiorni informazioni.
                        </p>
                    </div>
                    <div className="Menu-Contatti__blocco">
                        <h4>Link Utili:</h4>
                        <ul className="unstyledList">
                            <li><a href="https://bibliocrf.it">Sito Fondazione</a></li>
                            <li><a href="https://bibliocrf.it/privacy-e-note-legali/">Privacy Policy</a></li>
                            <li><a href="https://bibliocrf.it/privacy-e-note-legali/termini-di-utilizzo/">ToS</a></li>
                            <li><a href="https://kipoproduzioni.it">Crediti</a></li>
                        </ul>
                    </div>
                </div>
                </div>
            </div>
          </>
        )
    }
}

function OpenMenuBtn({onClick}) {
    return (
        <button id="Menu-Btn" className="btn-open" aria-label="apri il menu" onClick={onClick}>
          menu
        </button>
    );
}

function CloseMenuBtn({onClick}) {
    return (
        <button id="Menu-Btn" className="btn-close" aria-label="chiudi il menu" onClick={onClick}>
          chiudi
        </button>
    );
}


export default Menu;