import { useParams } from "react-router-dom";
import { urlify } from '../../utilities.js';

import './style.css';

// component
import Libro from '../Libro/Libro';

// file json con i dati di tutti i libri della collezione
import libri from '../../JSON/libri.json';

function getAuthorsAndBooksData(obj) {
    let arr = [];
    for ( const [autore, LIBRI] of Object.entries(obj) ) {
        for ( const [id, DATI] of Object.entries(LIBRI) ) {
            //let bookData = [];
            //for ( const [key, value] of Object.entries(DATI) ) {
            //    bookData.push(value);
            //}
            arr.push([autore, id, DATI]);
        }
    }
    return arr;
}

function filterAuthors(arr, autoreUrl) {
    return arr.filter(values => urlify(values[0]) === autoreUrl);
}

function Libreria() {
    let params = useParams();
    const FILTRO = params.autoreId;

    const COLLEZIONE_COMPLETA = getAuthorsAndBooksData(libri);
    let COLLEZIONE = COLLEZIONE_COMPLETA;

    if (FILTRO !== undefined) {
        // se nel link ho il valore di un autore mi mostra solo i suoi libri
        COLLEZIONE = filterAuthors(COLLEZIONE_COMPLETA, FILTRO);
    }

    return (
        <div className="Libreria">
            {
                COLLEZIONE.map( (value, index) => {
                    let autore = value[0];
                    let id = value[1];
                    let bookData = value[2];

                    const src = `/media/pages/${id}/${id}_0001.jpg`; // webp e avif vengono generate a partire dal jpg

                    return (
                        <Libro key={`Cover-${id}_${index}`} ID={id} cover={src} autore={autore} bookData={bookData}/>
                    )
                })
            }
        </div>
    )
}

export default Libreria;