import { useCallback, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import HTMLFlipBook from "react-pageflip";

import {Image} from '../Image/Image'; //{LazyImage}

import '../../utility.css';
import './style.css';

// file json con i dati di tutti i libri della collezione
import libri from '../../JSON/libri.json';




function getCurrentPage(currentPageNum,totPageNum) {
    if(currentPageNum < 1 && window.innerWidth > 600) {
        return currentPageNum;
    } else if (currentPageNum+1 >= totPageNum) {
        return totPageNum;
    } else {
        return "".concat((currentPageNum),'-',currentPageNum+1)
    }
}


function Lettura({ ID, bookData}) {

    const book = useRef();

    const [pageCount, setPageCount] = useState(1);

    const incrementPageNum = useCallback((e) => {
        setPageCount((c) => e.data);
    }, []);


    const onSliderInput = (e) => {
        let newPage = parseInt(e.target.value);
        setPageCount((c) => newPage);
        book.current.pageFlip().turnToPage(newPage);
    };



  const PAGES = [...Array(bookData.pagine).keys()].map(x => x+=1); //.filter( x => x >= parseInt(bookData.prima_pagina) ); 
  //Array con "numero pagine" di campi, a partire da 1 => [1, 2, 3, 4, 5, 6, 7, 8, 9], poi filtrato per iniziare con la "prima pagina"

  return (
    <div id="Leggio">
        <HTMLFlipBook 
            width={510}
            height={700}
            size="stretch"
            minWidth={290}
            maxWidth={1163}
            minHeight={400}
            maxHeight={1600}
            showCover={true}
            drawShadow={false}
            maxShadowOpacity={0.2}
            className="Wrapper--Book"
            onFlip={incrementPageNum}
            ref={book}
        >

        {
            PAGES.map((page, i) => {
                // ottengo quanti zeri vanno prima del numero della pagina a partire dal numero stesso
                let zeros = '';
                for (let z = 0; z < 4 - (page.toString().length); z++) {
                // il valore massimo è di 4 numeri, quindi per la lunghezza del numero, es 3 e 64
                // 3 -> 1 valore, quindi 0003; 64 -> 2 valori, quindi 0064
                zeros = zeros.concat('0');
                }

                const book_height = 700;

                const src = `/media/pages/${ID}/${ID}_${zeros}${page.toString()}.jpg`;

                return (
                        <div className="bookPage" data-density="soft" key={`${ID}_PAG-${i}`}>
                            <Image height={book_height} src={src} />
                        </div>
                    )
                /* Per aggiungere il lazyload
                if (i <= 4) {
                  return (
                    <div
                      className="bookPage"
                      data-density="soft"
                      key={`${ID}_PAG-${i}`}
                    >
                      <Image height={book_height} src={src} />
                    </div>
                  );
                } else {
                  return (
                    <div
                      className="bookPage"
                      data-density="soft"
                      key={`${ID}_PAG-${i}`}
                    >
                      <LazyImage height={book_height} src={src} />
                    </div>
                  );
                }*/

            })
        }
        </HTMLFlipBook>
        <div className="Container flex_row">
            <label htmlFor="pagesRange">{`Pagina [${getCurrentPage(pageCount,bookData.pagine,window.innerWidth)}] di ${bookData.pagine}`}</label>
            <input type="range" className="slider" id="pagesRange" name="pagesRange" min="1" max={bookData.pagine} value={pageCount} onInput={(e)=>onSliderInput(e)} />
        </div>
    </div>
  );
}

function getBookDataFromID(obj,ID) {
    let arr = [];
    for ( const [autore, LIBRI] of Object.entries(obj) ) {
        for ( const [id, DATI] of Object.entries(LIBRI) ) {
            if (id === ID) {
                arr.push(autore, id, DATI);
            }
        }
    }
    return arr;
}

function Leggio(){

    let params = useParams();
    const ID = params.libroId;

    let [autore, idLibro, DATI] = getBookDataFromID(libri,ID);

    return (
        <div>
            <Lettura ID={idLibro} bookData={DATI} />
            <div className="Details">
                <h1> {autore} &ndash; {DATI.titolo} </h1>
                <h2> Editore: {DATI.editore} | {DATI.anno} </h2>
                <h5> {DATI.info} </h5>
                <p> {DATI.desc} ... </p>
            </div>
        </div>
    )
}

export default Leggio;