//import LazyLoad from 'react-lazyload';
import './style.css';

// NON IN USO AL MOMENTO
/*
function LazyImage(props) {
    let alt = props.alt || "";
    let webp = props.src.split('.')[0]+'.webp';
    let avif = props.src.split('.')[0]+'.avif';
    return (
        <LazyLoad height={props.height} offset={0} once placeholder={
            <div style={{width: '100%', height: props.height, background: '#222'}}></div>
        }>
            <picture>
                <source srcset={avif} type="image/avif" />
                <source srcset={webp} type="image/webp" />
                <img className={props.className} src={props.src} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src="/fallback_fbcrf.jpg";
                }} alt={alt} />
            </picture>
        </LazyLoad>
    )
}
*/



function Image(props) {
    let alt = props.alt || "";
    let webp = props.src.split('.')[0]+'.webp';
    let avif = props.src.split('.')[0]+'.avif';
    return (
        <div className='image-wrapper'>
            <picture>
                <source srcset={avif} type="image/avif" />
                <source srcset={webp} type="image/webp" />
                <img loading='lazy' className={props.className} src={props.src} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src="/fallback_fbcrf.jpg";
                }} alt={alt} />
            </picture>
        </div>
    )
}


export {Image} //{LazyImage, Image}