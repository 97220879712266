import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Link} from "react-router-dom";
import 'normalize.css';
import './style.css';
import './utility.css';
import App from './App';
import Libreria from './components/Libreria/Libreria';
import Leggio from './components/Leggio/Leggio';
import reportWebVitals from './reportWebVitals';


function Err404(){
  return (
    <main style={{ padding: "1rem", textAlign: "center" }}>
      <p>Non troviamo il libro che stai cercando. <Link to="/"> Torna alla home.</Link></p>
    </main>
  );
}


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Libreria />}/>
          <Route path="collezione" element={<Libreria />}>
            <Route path=":autoreId" element={<Libreria />} />
          </Route>
          <Route path="leggio" element={<Leggio />}>
            <Route path=":libroId" element={
              <Suspense fallback={
                <div style={{textAlign: 'center', padding: '1em'}}>
                  <p>
                    <em>Stiamo caricando l'immagine richiesta.</em>
                    <br />
                    Prova a scrorrere la pagina in caso non si fosse ancora caricata l'immagine
                  </p>
                </div>
              }>
                <Leggio />
              </Suspense>
            } />
          </Route>
          <Route
            path="*"
            element={<Err404 />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
