import { Image }  from "../Image/Image";
import { Link } from 'react-router-dom';

import './style.css';
import '../../utility.css';


function Libro({autore, cover, bookData, ID}) {
    return (
      <Link to={`/leggio/${ID}`}>
        <div className="Book">
          <Image height="400" src={cover} className="Book-cover" alt={`Copertina di "${bookData.titolo}"`} />
          <div className="Book-info">
            <div className="Book-info__base flex_col">
              <p>{autore}</p>
              <h2>{bookData.titolo}</h2>
              <p><small>{`${bookData.editore}, ${bookData.anno}`}</small></p>
            </div>
            <div className="Book-info__overlay flex_col">
              <span>Leggi questo libro</span>
            </div>
          </div>
        </div>
      </Link>
      );
}

export default Libro;
