import { Link } from "react-router-dom";

import './style.css';
import '../../utility.css';

function Header() {
    return (
        <div className="Header flex_row">
            <Link to="/"><img src="/logo512.png" alt="Fondazione Biblioteche" className="Logo"/></Link>
            <h1><Link to="/">Biblioteca Storica Digitale</Link></h1>
        </div>
    )
}


export default Header;